<template>
  <div class="merchantadd-container position-relative">
    <div>
      <el-form
        label-width="120px"
        :model="merchantList"
        ref="addForm"
        :inline="true"
        :hide-required-asterisk="true"
      >
        <div>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="供货单号" prop="supplier_order_no">
                <div>{{ merchantList.supplier_order_no }}</div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="6">
              <el-form-item label="供货商名称" prop="title">
                <div v-if="merchantList.supplier">
                  {{ merchantList.supplier.title }}
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="联系电话" prop="mobile">
                <div v-if="merchantList.supplier">
                  {{ merchantList.supplier.mobile }}
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="采购员" prop="storeType">
                <div
                  v-if="merchantList.supplier && merchantList.supplier.buyer"
                >
                  {{ merchantList.supplier.buyer.fullname }}
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="档口位置" prop="stall">
                <div v-if="merchantList.supplier">
                  {{ merchantList.supplier.stall }}
                </div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="6">
              <el-form-item label="供货单金额" prop="product_amount">
                <div>
                  {{
                    $empty.empty(
                      tool.format_money(merchantList.product_amount)
                    )
                  }}元
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="报损扣款金额">
                <div v-if="merchantList.liquidation_status == 0">—元</div>
                <div v-else>
                  <!-- 20231227，配合王鹤龄修改
                    tool.format_money(
                      merchantList.product_amount -
                        merchantList.liquidation_amount
                    ) -->
                  {{ tool.format_money(merchantList.damage_amount) }}元
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="结算金额" prop="liquidation_amount">
                <div v-if="merchantList.liquidation_status == 0">—元</div>
                <div v-else>
                  {{
                    $empty.empty(
                      tool.format_money(merchantList.liquidation_amount)
                    )
                  }}元
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item :label="serviceFeeLabel" prop="service_fee_amount">
                <div>{{ `${merchantList.service_fee_amount || 0}元` }}</div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="6">
              <el-form-item label="平台服务费" prop="supplier_order_no">
                <div>
                  {{ `${merchantList.platform_fee_amount || 0}元` }}
                </div>
              </el-form-item>
            </el-col>
          </el-row>
          <div class="auto-table-flex mb-30">
            <el-collapse v-model="activeName" accordion>
              <el-collapse-item name="1">
                <template slot="title">
                  <div class="d-flex j-sb" style="width: 100%">
                    <div class="font-weight">供货单商品列表</div>
                    <div class="d-flex">
                      <div class="mr40 font-weight">
                        供货总数量：<span class="main-color">{{
                          $empty.empty(merchantList.product_number)
                        }}</span>
                      </div>
                      <div class="font-weight">
                        供货总金额：<span class="main-color"
                          >{{
                            $empty.empty(
                              tool.format_money(merchantList.product_amount)
                            )
                          }}元</span
                        >
                      </div>
                    </div>
                  </div>
                </template>
                <el-table
                  :data="goodsData"
                  v-loading="loading"
                  :border="true"
                  :show-header="false"
                  :header-cell-style="{
                    color: '#333333',
                    background: '#EFF6FF',
                  }"
                >
                  <el-table-column
                    type="index"
                    width="80"
                    align="center"
                    label="序号"
                  >
                  </el-table-column>
                  <el-table-column
                    prop="goodsName"
                    align="center"
                    label="商品主图"
                  >
                    <template slot-scope="scope" v-if="scope.row.product">
                      <img
                        @click.stop="getGlass(scope.row.product.kv)"
                        class="kv pointer"
                        :src="scope.row.product.kv"
                        alt=""
                      />
                    </template>
                  </el-table-column>
                  <el-table-column prop="id_card" align="center" label="名称">
                    <template slot-scope="scope">
                      <span
                        >{{ $empty.empty(scope.row.product.title) }} ({{
                          $empty.empty(scope.row.product.sku_code)
                        }})</span
                      >
                    </template>
                  </el-table-column>
                  <el-table-column prop="grade" align="center" label="等级">
                    <template slot-scope="scope" v-if="scope.row.product">
                      <span
                        >等级：{{ $empty.empty(scope.row.product.grade) }}</span
                      >
                    </template>
                  </el-table-column>
                  <el-table-column prop="weight" align="center" label="净重">
                    <template slot-scope="scope" v-if="scope.row.product">
                      <span
                        >净重：{{
                          $empty.empty(scope.row.product.weight)
                        }}斤</span
                      >
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="gross_weight"
                    align="center"
                    label="毛重"
                  >
                    <template slot-scope="scope" v-if="scope.row.product">
                      <span
                        >毛重：{{
                          $empty.empty(scope.row.product.gross_weight)
                        }}斤</span
                      >
                    </template>
                  </el-table-column>
                  <el-table-column prop="grade" align="center" label="供货数量">
                    <template slot-scope="scope">
                      <span
                        >供货数量：{{
                          $empty.empty(scope.row.product_number)
                        }}</span
                      >
                    </template>
                  </el-table-column>
                  <el-table-column prop="grade" align="center" label="供货金额">
                    <template slot-scope="scope">
                      <span
                        >供货金额：{{
                          $empty.empty(
                            tool.format_money(scope.row.product_amount)
                          )
                        }}元</span
                      >
                    </template>
                  </el-table-column>
                </el-table>
              </el-collapse-item>
              <el-collapse-item name="2">
                <template slot="title">
                  <div class="d-flex j-sb" style="width: 100%">
                    <div class="font-weight">报损商品列表</div>
                    <div class="d-flex">
                      <div
                        class="font-weight"
                        v-if="merchantList.liquidation_status == 0"
                      >
                        报损总金额：<span class="main-color">—元</span>
                      </div>
                      <div class="font-weight" v-else>
                        报损总金额：<span class="main-color">
                          <!-- 20231227，配合王鹤龄修改
                            tool.format_money(
                              merchantList.product_amount -
                                merchantList.liquidation_amount
                            ) -->
                          {{
                            tool.format_money(merchantList.damage_amount)
                          }}元</span
                        >
                      </div>
                    </div>
                  </div>
                </template>
                <el-table
                  :data="breakData"
                  v-loading="loading"
                  :border="true"
                  :show-header="false"
                  :header-cell-style="{
                    color: '#333333',
                    background: '#EFF6FF',
                  }"
                >
                  <el-table-column
                    type="index"
                    width="80"
                    align="center"
                    label="序号"
                  >
                  </el-table-column>
                  <el-table-column
                    prop="goodsName"
                    align="center"
                    label="商品主图"
                  >
                    <template slot-scope="scope" v-if="scope.row.product">
                      <img
                        @click.stop="getGlass(scope.row.product.kv)"
                        class="kv pointer"
                        :src="scope.row.product.kv"
                        alt=""
                      />
                    </template>
                  </el-table-column>
                  <el-table-column prop="id_card" align="center" label="名称">
                    <template slot-scope="scope">
                      <span
                        >{{ $empty.empty(scope.row.product.title) }} ({{
                          $empty.empty(scope.row.product.sku_code)
                        }})</span
                      >
                    </template>
                  </el-table-column>
                  <el-table-column prop="grade" align="center" label="等级">
                    <template slot-scope="scope" v-if="scope.row.product">
                      <span
                        >等级：{{ $empty.empty(scope.row.product.grade) }}</span
                      >
                    </template>
                  </el-table-column>
                  <el-table-column prop="weight" align="center" label="净重">
                    <template slot-scope="scope" v-if="scope.row.product">
                      <span
                        >净重：{{
                          $empty.empty(scope.row.product.weight)
                        }}斤</span
                      >
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="gross_weight"
                    align="center"
                    label="毛重"
                  >
                    <template slot-scope="scope" v-if="scope.row.product">
                      <span
                        >毛重：{{
                          $empty.empty(scope.row.product.gross_weight)
                        }}斤</span
                      >
                    </template>
                  </el-table-column>
                  <el-table-column prop="grade" align="center" label="报损金额">
                    <template slot-scope="scope">
                      <span
                        >报损金额：{{
                          tool.format_money(scope.row.refund_amt)
                        }}元</span
                      >
                    </template>
                  </el-table-column>
                  <el-table-column prop="grade" align="center" label="报损原因">
                    <template slot-scope="scope">
                      <span>报损原因：{{ $empty.empty(scope.row.desc) }}</span>
                    </template>
                  </el-table-column>
                </el-table>
              </el-collapse-item>
            </el-collapse>
          </div>
        </div>
      </el-form>
    </div>

    <div class="fy1" style="position: absolute; bottom: 0; left: 0; right: 0">
      <el-button @click="$router.back()">返回</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      merchantList: {},
      id: "",
      goodsData: [
        //商品信息
      ],
      bigImage: "",
      showImage: false,
      activeName: "1",
      breakData: [],
      loading: true,
      // 手续费比例文案
      serviceFeeLabel: "",
    };
  },
  created() {
    if (this.$route.query && this.$route.query.id) {
      this.id = this.$route.query.id;
      this.getDetail();
    }
  },
  methods: {
    // 获取详情
    getDetail() {
      this.$api.general.settleDetail({ id: this.id }).then((res) => {
        this.merchantList = res.data;
        this.goodsData = res.data.supplier_order_detail;
        this.breakData = res.data.supplier_order_liquidation_product_aftersale;
        const { service_fee_title } = res.data;
        this.serviceFeeLabel = `金融手续费(${service_fee_title || "0.4%"})`;
        this.loading = false;
      });
    },
    getGlass(src) {
      this.bigImage = src;
      this.showImage = true;
    },
    handleChange(value) {
      console.log(value);
      this.merchantList.areaIds = `${value[0]}_${value[1]}_${value[2]}`;
      console.log(this.merchantList.areaIds);
      // this.originId = value[value.length-1]
      // this.originId = value
    },
  },
};
</script>

<style lang="less">
.merchantadd-container {
  .kv {
    width: 100px;
    height: 100px;
  }

  .sale-font {
    font-size: 15px;
  }

  padding: 10px;
  background-color: #ffffff;
  box-sizing: border-box;
  min-height: calc(100% - 20px);

  .warehouse-wrapper {
    width: 302px;
    height: 37px;
    line-height: 37px;
    text-align: center;
    background: #f2fcff;
    border-radius: 2px;
    border: 1px dashed #349ee6;
    margin-bottom: 20px;
    cursor: pointer;

    .plus {
      width: 14px;
      height: 14px;
    }
  }

  .warehouse-container {
    .warehouse-box {
      border-radius: 2px;
      border: 1px solid #dfe3ef;
      margin-bottom: 20px;
      padding: 11px 13px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      div {
        line-height: 1;
      }

      .box-img {
        width: 14px;
        height: 14px;
        cursor: pointer;
      }
    }
  }

  .footerbtn {
    position: fixed;
    right: 20px;
    top: 13%;
  }

  .allmoney {
    position: fixed;
    left: 210px;
    right: 20px;
    height: 76px;
    padding: 0 30px;
    line-height: 76px;
    background: #fffcf9;
    box-shadow: 0px -3px 8px 0px rgba(255, 140, 89, 0.3);
    border-radius: 6px;
    bottom: 40px;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
  }

  .allmoney-font {
    font-size: 18px;
  }

  .auto-table-flex .el-table__footer-wrapper tbody td.el-table__cell {
    background-color: #eff6ff;
  }

  .auto-table-flex .el-table .el-table__footer-wrapper .cell {
    color: #e65a34;
  }
}
</style>
